import { useContext, useEffect, useState } from "react";
import { ModalContext } from "./modal";
import { useIndexQuery } from "../shared/use-rest-query";
import { ReferPatientModalReferralType } from "./refer-patient-modal/referral-type";
import { ReferPatientModalSuccess } from "./refer-patient-modal/success";
import { classNames } from "../shared/classnames";
import { ReferPatientModalError } from "./refer-patient-modal/error";
import { useIsTabFocused } from "../shared/use-is-tab-focused";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";
import { AuthorizationContext } from "../providers/authorization-provider";

export const ReferPatientModal = (props) => {
	const { practice } = useContext(AuthorizationContext);
	const { setShowModal } = useContext(ModalContext);
	const closeModal = () => setShowModal(false);
	const [step, setStep] = useState("ReferralType");

	const {
		data: { data: practiceStudies },
	} = useIndexQuery("public/practice_studies", practice?.practice_id);

	const isTabFocused = useIsTabFocused();
	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		event({
			eventName: `ReferPatientModalView${isTabFocused ? "Start" : "End"}`,
			getAccessTokenSilently,
		});
	}, [isTabFocused, getAccessTokenSilently]);

	useEffect(() => {
		return () => {
			event({
				eventName: "ReferPatientModalViewEnd",
				getAccessTokenSilently,
			});
		};
	}, [getAccessTokenSilently]);

	// WIZARD 🪄 STEPS
	// referralType (indication / study)
	// study list
	// indication list
	// refer by study
	// refer by indication
	// refer with i/e criteria
	// success page

	return (
		<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
			<div className={step === "ReferralType" ? "block" : "hidden"}>
				<ReferPatientModalReferralType
					practiceStudies={practiceStudies}
					setStep={setStep}
				/>
			</div>
			<div className={step === "Success" ? "block" : "hidden"}>
				<ReferPatientModalSuccess />
			</div>
			<div className={step === "Error" ? "block" : "hidden"}>
				<ReferPatientModalError />
			</div>
		</div>
	);
};
