import { useContext } from "react";
import { AuthorizationContext } from "../providers/authorization-provider";
import { useIndexQuery, useShowQuery } from "../shared/use-rest-query";

export const StudyBreadcrumb = ({ match={params: null} }) => {
	const {
		data: { data: practiceStudy },
		isLoading,
		isFetching,

	} = useShowQuery("public/practice_studies", match.params.practiceStudyId)

	return practiceStudy?.study?.abbreviation || "Details"
};
