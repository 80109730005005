import { Link } from "react-router-dom";

export const TermsOfServiceContent = () => {
	return (
		<div>
			<div className="p-6 text-gray-800">
				<h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
				<p className="mb-4">
					These Terms of Service (“Terms”) govern your use of our Site, which is
					owned or operated by Topography. Please read these Terms and the
					Privacy Policy carefully before you access the Services, as these
					Terms form a binding legal agreement between you and Topography.
				</p>
				<p className="mb-4">
					These Terms may apply to you individually or to the business or other
					legal entity you represent, or both. If you are using the Site or
					Services on behalf of a company or other legal entity, you hereby
					represent and warrant that you have the authority to enter into these
					Terms on behalf of such entity. By accessing, registering for, or
					using the Services, you: (1) acknowledge that you have read and
					understand these Terms; (2) agree to be bound by them in their
					entirety, and (3) are entering into a legally binding agreement with
					us.
				</p>
				<p className="mb-4">
					As used in these Terms and unless separately identified as applicable
					to either an individual or entity, “you” and “your” refer to both you
					individually and the entity on behalf of which you are entering into
					these Terms.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Definitions</h2>
				<ul className="list-disc list-inside mb-4">
					<li>
						“Content” means text, data, graphics, images, videos, information,
						and other content included on the Site that is created by us or
						other third parties.
					</li>
					<li>“Services” means the services made available via the Site.</li>
					<li>
						“Site” means Topography's websites, including, without limitation
						investigators.jointopo.com, and including any content or information
						provided as part of the Site, and any other web pages operated by
						Topography, as Topography may amend from time to time.
					</li>
					<li>
						“Topography” or “we” or “us” or “our” means Topography Health, Inc.
					</li>
				</ul>
				<p className="mb-4 font-bold">
					IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THE SERVICES.
					YOUR USE OF OUR SERVICES REQUIRES YOUR ACCEPTANCE OF THESE TERMS AS
					THEY MAY BE AMENDED FROM TIME TO TIME, INCLUDING THE POLICIES
					INCORPORATED BY REFERENCE HEREIN, WHICH INCLUDES THE PRIVACY POLICY.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">License</h2>
				<p className="mb-4">
					The Site is for your personal and non-commercial use only. You may not
					modify, copy, distribute, transmit, display, perform, reproduce,
					publish, license, create derivative works from, transfer, or sell any
					information, software, products, or services obtained from the Site.
					Accordingly, Topography grants you a limited license to access and use
					the Site solely for your personal, non-commercial use, provided that
					you do not modify the Site, its content, or any copyright or other
					proprietary notices. In addition, you agree that you will not use the
					Site in any manner that could damage, disable, or impair the Site or
					interfere with other users' use of the Site. You agree that you will
					not use or access the Site or Topography's confidential information
					for any competitive purpose. Your use of the Site is at the discretion
					of Topography and we may terminate your use at any time.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Ownership</h2>
				<p className="mb-4">
					All right, title, and interest in and to the Site, the Content, and
					the structure, organization, and arrangement thereof, are and remain
					the exclusive property of Topography and is protected by law,
					including, but not limited to, United States copyright law. Under no
					circumstances will you acquire any ownership rights or other interest
					in the Site or any Content by or through your use of the Site. Except
					for the limited rights granted above, all other rights are reserved.
					Topography welcomes your suggestions to improve its Site and Services.
					If you provide feedback to Topography, you agree that such feedback,
					including all ideas and concepts within the feedback, shall be the
					property of Topography and you hereby assign to Topography your
					ownership rights in the feedback.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Confidentiality</h2>
				<p className="mb-4">
					You agree that any confidential and proprietary information (including
					Content and Site design and functionality) that are disclosed by
					Topography to you, or observed by you as a user of the Site, and that
					are not publicly available, including, without limitation, the Site,
					Content, and the structure, organization, and arrangement thereof, any
					APIs, and any associated documentation, and any and all data or
					information contained therein or related thereto constitute
					Topography's confidential information (“Confidential Information”).
					You will not access or use Confidential Information, other than as
					expressly permitted in these Terms. You will not disclose Confidential
					Information to any third party. This “Confidentiality” section shall
					survive termination of these Terms and shall continue to apply until
					the information is generally available to the public without
					restriction through no fault of you or your affiliates, agents,
					consultants, or employees.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Accounts</h2>
				<p className="mb-4">
					You may not permit any other person to access your account using your
					user name and password. The security of your password and the use of
					your account is your responsibility. If you learn or suspect that your
					user name or password has been wrongfully used or disclosed, you
					should promptly notify Topography. To help ensure the security of your
					password or account, please sign out of your account at the end of
					each session.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Content</h2>
				<p className="mb-4">
					The Content provided is for general information purposes only, and
					should not be relied upon for personal, professional, medical,
					financial, or regulatory decisions. We do not ensure the completeness,
					timeliness, or accuracy of the Content.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Beta Services</h2>
				<p className="mb-4">
					Topography may make certain Services, features, or functionality
					available at no charge, which may be designated by Topography as a
					beta or a similar description (each, a “Beta Service” and
					collectively, the “Beta Services”). You may test and evaluate the Beta
					Services in your sole discretion and at your own risk. Beta Services
					are intended for evaluation purposes, are not generally available, and
					may contain bugs and errors. YOU ACKNOWLEDGE AND AGREE THAT BETA
					SERVICES ARE PROVIDED “AS-IS” WITH RESPECT TO PERFORMANCE, SPEED,
					FUNCTIONALITY, SUPPORT, AND AVAILABILITY AND TOPOGRAPHY WILL HAVE NO
					LIABILITY OR OBLIGATION FOR ANY HARM OR DAMAGE ARISING FROM
					DEFICIENCIES THEREWITH.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Term and Termination
				</h2>
				<p className="mb-4">
					These Terms commence on the date you enable or use the Site (including
					any Beta Service) and will remain in effect unless either party
					provides written notice of termination upon thirty days' written
					notice to the other. Topography may discontinue or suspend the Site
					and/or the Services at any time.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Compliance with Laws
				</h2>
				<p className="mb-4">
					As a condition of your use of the Site, you represent and warrant to
					Topography that you will not use the Site for any purpose that is
					unlawful, offensive, interferes with the Site, infringes anyone's
					rights, or in a manner that is prohibited by these Terms, and that you
					have taken all actions to comply with all applicable laws. As part of
					the use of the Site, you may wish to disclose Protected Health
					Information (as defined by the Health Insurance Portability and
					Accountability Act, as amended, and its implementing regulations,
					collectively “HIPAA”), which may be stored on servers managed by
					Topography or its subprocessors. You agree to obtain all appropriate
					and necessary consents and authorizations to use or disclose any
					personally identifiable information in compliance with all federal and
					state privacy laws prior to your use or disclosure to the Site.
					Without limiting any other rights agreed upon by the Parties in any
					separate services agreement, the Parties agree that Topography may use
					“protected health information” provided by you via the Site in
					furtherance of “reviews preparatory to research” (as defined by HIPAA)
					and to perform the Services.
				</p>

				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Submissions and Communication
				</h2>
				<p className="mb-4">
					When you use the Site or send communications to us through the Site,
					you are communicating with us electronically. You consent to receive
					any communications related to your use of this Site electronically. By
					submitting information or otherwise providing us with your email
					address, postal address, or phone number, you are agreeing that we or
					our agents may contact you at that address or number in a manner
					consistent with our Privacy Policy.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Indemnification</h2>
				<p className="mb-4">
					You agree to indemnify, defend, and hold harmless Topography and its
					affiliates, officers, directors, employees, agents, representatives,
					partners, and licensors from and against any and all actions,
					liabilities, losses, claims, proceedings, suits, damages, penalties,
					fines, settlements and expense, including reasonable attorney's fees,
					arising out of, related to, or in any way connected with your
					violation of these Terms or your use of the Site.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Disclaimer</h2>
				<p className="mb-4">
					The Site and the Content contained in the Site may include
					inaccuracies or typographical errors. TOPOGRAPHY AND ITS AFFILIATES
					MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
					TIMELINESS, AND ACCURACY OF THE INFORMATION, CONTENT, AND RELATED
					GRAPHICS CONTAINED ON THIS SITE FOR ANY PURPOSE. ALL SUCH INFORMATION
					AND THE CONTENT ON THIS SITE ARE PROVIDED ON AN “AS IS” AND “AS
					AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND. YOU AGREE THAT USE OF
					THE SITE IS AT YOUR SOLE RISK. TOPOGRAPHY AND ITS AFFILIATES HEREBY
					DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY
					EXPRESS WARRANTIES, STATUTORY WARRANTIES AND ANY IMPLIED WARRANTIES OF
					ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
					NON-INFRINGEMENT.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Limitation of Liability
				</h2>
				<p className="mb-4">
					UNDER NO CIRCUMSTANCES WILL TOPOGRAPHY OR OUR RESPECTIVE OFFICERS,
					DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS OR LICENSORS
					(COLLECTIVELY, THE “TOPOGRAPHY ENTITIES”) BE LIABLE OR RESPONSIBLE FOR
					ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES
					FROM LOSS OF BUSINESS, LOST PROFITS, LOSS OF GOODWILL, LOSS OF
					INFORMATION, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE,
					OR OTHER DAMAGES, WHETHER BASED ON CONTRACT, WARRANTY, NEGLIGENCE,
					STRICT LIABILITY OR UNDER ANY OTHER THEORY OF LIABILITY, ARISING OUT
					OF OR IN ANY WAY RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT,
					EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE AND
					EXCLUSIVE REMEDY FOR DISSATISFACTION OR CLAIMS WITH THE SITE, ITS
					CONTENT, AND/OR THESE TERMS IS TO DISCONTINUE YOUR USE OF THIS SITE.
					IN THE CASE OF A JURISDICTION THAT RESTRICTS LIMITATION CLAUSES, THIS
					LIMITATION WILL BE APPLIED TO THE GREATEST EXTENT PERMITTED BY LAW.
					NOTHING IN THESE TERMS OF USE IS INTENDED TO LIMIT ANY RIGHTS YOU MAY
					HAVE THAT MAY NOT BE LAWFULLY TERMINATED.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Injunctive Relief</h2>
				<p className="mb-4">
					You acknowledge that any breach, threatened or actual, of these Terms
					would cause irreparable injury to Topography not readily quantifiable
					as money damages, such that Topography would not have an adequate
					remedy at law. You therefore agree that Topography shall be entitled,
					in addition to other available remedies, to seek and be awarded an
					injunction or other appropriate equitable relief from a court of
					competent jurisdiction restraining any such breach of your
					obligations, without the necessity of posting bond or other security.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">Modifications</h2>
				<p className="mb-4">
					Topography reserves the right to modify the Site and these Terms at
					any time by posting the modified language on the Site, with or without
					notice to you. Posted modifications will become effective immediately,
					and your use of the Site following any such modifications constitutes
					your agreement to followand be bound by the Terms as modified.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Applicable Law</h2>
      <p className="mb-4">
        You agree that the laws of the State of California, without regard to principles of conflicts of laws, will govern these Terms and any dispute between you and Topography that might arise out of your use of this Site, the Services, and/or the Content.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Waiver and Severability</h2>
      <p className="mb-4">
        Failure to enforce any provision in these Terms will not constitute a waiver of that provision. If any provision is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of these Terms will remain in effect.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Entire Agreement</h2>
      <p className="mb-4">
        These Terms, together with any terms and policies that are incorporated by reference, constitute the entire agreement and supersede any prior agreements between you and Topography with respect to your use of the Site and its Content.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Copyright and Trademark Notices</h2>
      <p className="mb-4">
        All contents of this Site are: Copyright © 2024 Topography Health, Inc. All rights reserved. Topography Health and other names of Topography products and/or services referenced herein are trademarks or registered trademarks of Topography Health, Inc.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Privacy Policy</h2>
      <p className="mb-4">
        Your visit to this Site is also governed by our Privacy Policy, which can be found here: <Link className="text-blue-500" to="/privacy-policy">Privacy Policy</Link>.
      </p>
      <p className="mb-4">
        Last Modified: June 19, 2024
      </p>
    </div>
		</div>
	);
};
