import { useContext, useEffect, useState } from "react";
import { BellIcon } from "../components/icons/bell-icon";
import { StudyCard } from "../components/study-card";
import { useIndexQuery } from "../shared/use-rest-query";
import { AuthorizationContext } from "../providers/authorization-provider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CardLoader from "../components/card-loader";
import { Select } from "flowbite-react";
import { customPracticeStudySort } from "../shared/custom-practice-study-sort";
import { uniqueIndications } from "../shared/unique-indications";
import { useIsTabFocused } from "../shared/use-is-tab-focused";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";

export const Studies = () => {
	const { practice } = useContext(AuthorizationContext);
	const {
		data: { data: practiceStudies },
		isFetching,
	} = useIndexQuery("public/practice_studies", practice?.practice_id);
	const [showReferWidget, setShowReferWidget] = useState(true);
	const [indication, setIndication] = useState("all");

	const handleIndicationChange = (e) => {
		setIndication(e.target.value);
	};

	const filteredPracticeStudies = (practiceStudies) =>
		practiceStudies?.filter(
			(practiceStudy) =>
				indication === "all" || practiceStudy?.study?.indication === indication,
		);

	const isTabFocused = useIsTabFocused();
	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		event({
			eventName: `StudiesView${isTabFocused ? "Start" : "End"}`,
			getAccessTokenSilently,
		});
	}, [isTabFocused, getAccessTokenSilently]);
	useEffect(() => {
		return () => {
			event({
				eventName: "StudiesViewEnd",
				getAccessTokenSilently,
			});
		};
	}, [getAccessTokenSilently]);

	return (
		<div className="px-4 min-h-full">
			<div className="font-bold text-xl pt-2 pb-4">
				Studies{" "}
				{practice?.practice_name ? (
					`at ${practice?.practice_name}`
				) : (
					<Skeleton />
				)}
			</div>
			<div>
				{/* <div className="text-sm font-semibold">Indication</div> */}
				{/* Check in tailwind config for this color, or the flowbite provider. */}
				<Select
					id="indications"
					value={indication}
					color="gray"
					className="mb-2"
					onChange={handleIndicationChange}
				>
					<option value="all">All Indications</option>
					{uniqueIndications(practiceStudies)
						.sort()
						.map((indication) => {
							return <option key={indication}>{indication}</option>;
						})}
				</Select>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
				{filteredPracticeStudies(customPracticeStudySort(practiceStudies)).map(
					(practiceStudy) => (
						<div
							key={practiceStudy.id}
							className="space-y-2 sm:w-full sm:min-w-64"
						>
							<StudyCard practiceStudy={practiceStudy} />
						</div>
					),
				)}
				{isFetching &&
					Array.from({ length: 9 }, (_, index) => index + 1).map((key) => (
						<div key={key} className="space-y-2 sm:w-full sm:min-w-64">
							<CardLoader />
						</div>
					))}
			</div>
			<div className="hidden mb-4 max-w-sm">
				<button
					type="button"
					onClick={() => setShowReferWidget(!showReferWidget)}
					className="flex w-full items-center justify-center px-3 py-4 text-normal font-medium text-center text-white bg-[#2BB673] rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
				>
					<div className="flex items-center">
						<BellIcon />
						<div className="">Refer a Patient</div>
					</div>
				</button>
			</div>
		</div>
	);
};
