import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton, InputAdornment } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const TailwindDatePicker = ({ ...props }) => {
	const [open, setOpen] = useState(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				open={open}
				onClose={() => setOpen(false)}
				{...props}
				slotProps={{
					textField: {
						placeholder: "Optional",
						InputProps: {
							startAdornment: (
								<InputAdornment position="start">
									<IconButton
										onClick={() => setOpen(true)}
										edge="start"
										className="absolute inset-y-0 start-0 flex items-center"
									>
										<CalendarTodayIcon className="w-3 h-3 text-gray-600 dark:text-white" />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: null,
						},
						className:
							"bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",

						sx: {
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.5rem", // Equivalent to rounded-lg
								backgroundColor: "rgb(249 250 251)", // bg-gray-50
								"& fieldset": {
									borderColor: "rgb(209 213 219)", // border-gray-300
								},
								"&:hover fieldset": {
									borderColor: "rgb(209 213 219)",
								},
								"&.Mui-focused fieldset": {
									borderColor: "rgb(59 130 246)", // focus:border-blue-500
								},
							},
							"& .MuiInputBase-input": {
								display: "flex",
								marginLeft: "-0.5rem",
								fontSize: "0.875rem", // Equivalent to text-sm
								lineHeight: "1.25rem",
								color: "rgb(17 24 39)", // text-gray-900
								outline: "none",
								"&:focus": {
									outline: "none", // Remove the focus outline only from the input
								},
							},
							"&:hover": {
								outline: "none",
							},
							// Style for the placeholder text
							"& .MuiInputBase-input::placeholder": {
								color: "rgb(156 163 175)", // text-gray-400
							},
							// Override any potential focus styles
							"& .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:focus":
								{
									outline: "none",
									boxShadow: "none",
								},
							"& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd, & .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input":
								{
									"&::placeholder": {
										color: "rgb(116, 124, 137)",
										opacity: 1,
									},
								},
						},
					},
				}}
			/>
		</LocalizationProvider>
	);
};

export default TailwindDatePicker;
