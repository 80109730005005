import { useCreate } from "./use-rest-query";

const SOURCE = "provider_dash";

export const event = async ({
	eventName,
	meta = {},
	getAccessTokenSilently,
}) => {
	const data = { name: eventName, meta: meta };
	if (process.env.ENV === "dev") {
		console.log(
			`Event: firing event with name: ${eventName} meta: ${JSON.stringify(
				meta,
			)}`,
		);
	}
	useCreate(
		"public/events",
		{ ...data, source: SOURCE },
		getAccessTokenSilently,
	);
};
