import { useState } from "react";
import { CalendarWeekView } from "./calendar/calendar-week-view";
import { CalendarDayView } from "./calendar/calendar-day-view";
import { CalendarMonthView } from "./calendar/calendar-month-view";
import { CalendarYearView } from "./calendar/calendar-year-view";

export const Calendar = () => {
	const [view, setView] = useState('week')
	
	// Really don't know if this is the best way to structure it..
	// prob not.  pull the switcher up.
	switch (view) {
		case 'day':
			return <CalendarDayView setView={setView} />
		case 'week':
			return <CalendarWeekView setView={setView} />
		case 'month':
			return <CalendarMonthView setView={setView} />
		case 'year':
			return <CalendarYearView setView={setView} />
		default:
			return <CalendarWeekView setView={setView} />;
	}
};
