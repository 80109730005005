import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { createContext } from "react";

// I might move this up to Layout, but for now it's here.
export const ModalContext = createContext({ setShowModal: (boolean) => {} });

export const Modal = (props) => {
	const { setShowModal, title, showModal, children } = props;
	const closeModal = () => setShowModal(false);
	return (
		<Transition show={showModal}>
			<Dialog className="relative z-50" onClose={closeModal}>
				<TransitionChild
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
						<TransitionChild
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="w-full h-screen md:h-auto md:w-auto">
								<ModalContext.Provider value={{ setShowModal }}>
									{children}
								</ModalContext.Provider>
							</div>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
