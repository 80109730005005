import React, { useEffect } from "react";
import { PrivacyPolicyContent } from "./terms-of-service/privacy-policy-content";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";
import { useIsTabFocused } from "../shared/use-is-tab-focused";

export const PrivacyPolicy = () => {
	const { getAccessTokenSilently } = useAuth0();
	const isTabFocused = useIsTabFocused();
	useEffect(() => {
		event({
			eventName: `PrivacyPolicyView${isTabFocused ? "Start" : "End"}`,
			getAccessTokenSilently,
		});
	}, [isTabFocused, getAccessTokenSilently]);

	useEffect(() => {
		return () => {
			event({
				eventName: "PrivacyPolicyViewEnd",
				getAccessTokenSilently,
			});
		};
	}, [getAccessTokenSilently]);
	return (
		<div className="flex justify-center pt-10">
			<div className="w-2/3">
				<PrivacyPolicyContent />
			</div>
		</div>
	);
};
