import {
	Link,
	Navigate,
	Outlet,
	useLocation,
	useMatch,
	useNavigate,
	useResolvedPath,
} from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
	Calendar,
	ChartBar,
	ChartDonut,
	ChartLine,
	ChartPie,
	CheckCircle,
	ClipboardText,
	Gear,
	Money,
	Phone,
	PhoneCall,
	PhoneOutgoing,
	PresentationChart,
	UserCirclePlus,
} from "@phosphor-icons/react";
import { AuthorizationContext } from "./providers/authorization-provider";
import { Breadcrumbs } from "./components/breadcrumbs";
import { FooterCta, ReferPatient } from "./components/footer-cta";
import { classNames } from "./shared/classnames";
import { ReferPatientModal } from "./components/refer-patient-modal";
import { Modal } from "./components/modal";
import { Avatar } from "./components/avatar";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "./shared/events";
import { TenantSelector } from "./components/tenant-selector";

export const Layout = ({ children }) => {
	const logoFullColor = new URL(
		"./assets/logo-full-color.svg",
		import.meta.url,
	).toString();
	const utah = new URL("./assets/utah.png", import.meta.url).toString();

	const useMatchesPath = (path) =>
		useMatch({
			path: useResolvedPath(path).pathname,
		});

	const studiesIsActivePaths = ["/", "/studies"];
	const studiesIsActive = studiesIsActivePaths
		.map((path) => useMatchesPath(path))
		.some((match) => match !== null);

	const [showSidebar, setShowSidebar] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(<div />);

	const location = useLocation();

	// Close the sidebar when the location changes
	useEffect(() => {
		if (!location) return;
		setShowSidebar(false);
		setShowUserMenu(false);
	}, [location]);

	const [showUserMenu, setShowUserMenu] = useState(false);
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { user, practice } = useContext(AuthorizationContext);
	// TODO: Look this up from /practices API
	const practicePhone = "(303) 228-8616,1"; // RMG

	const handleNavClick = async (navElement) => {
		event({
			eventName: "Click",
			meta: {
				element: `Nav${navElement?.label}`,
			},
			getAccessTokenSilently,
		});
	};

	const handleUserMenuClick = async (profileElement) => {
		event({
			eventName: "Click",
			meta: {
				element: profileElement,
			},
			getAccessTokenSilently,
		});
	};

	// TODO: move this to routes, with a "show in sidebar" config
	const navConfig = [
		{
			label: "Studies",
			path: "/studies",
			icon: <ClipboardText weight="bold" size="20" />,
		},
		{
			label: "Tasks",
			path: "/tasks",
			icon: <CheckCircle weight="bold" size="20" />,
			hidden: true,
		},
		{
			label: "Finances",
			path: "/finances",
			hidden: true,
			icon: <Money weight="bold" size="20" />,
		},
		{
			label: "Metrics",
			path: "/metrics",
			icon: <ChartLine weight="bold" size="20" />,
		},
		// {
		// 	label: "Calendar",
		// 	path: "/calendar",
		// 	icon: <Calendar weight="bold" size="20" />,
		// },
	];

	const navElements = navConfig.map((element) => ({
		...element,
		match: useMatch(element.path),
	}));

	if (!isAuthenticated) return <></>;

	return (
		<div className="h-full antialiased bg-gray-50 dark:bg-gray-900">
			{/* Modal */}
			{/* <div className={`${showModal ? "block" : "hidden"}`}> */}
			<Modal setShowModal={setShowModal} showModal={showModal}>
				<ReferPatientModal />
			</Modal>
			{/* </div> */}
			<nav className="bg-white border-b border-gray-200 px-1 md:px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
				<div className="flex flex-wrap justify-between items-center">
					<div className="flex justify-start items-center">
						<button
							onClick={() => {
								setShowSidebar(!showSidebar);
								setShowUserMenu(false);
							}}
							type="button"
							data-drawer-target="drawer-navigation"
							data-drawer-toggle="drawer-navigation"
							aria-controls="drawer-navigation"
							className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
						>
							<svg
								aria-hidden="true"
								className="w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
									clipRule="evenodd"
								/>
							</svg>
							<svg
								aria-hidden="true"
								className="hidden w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">Toggle sidebar</span>
						</button>
						<a href="/" className="flex items-center justify-between mr-4">
							<img alt="logo" src={logoFullColor} className="h-8" />
							{/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
									Topography Health
								</span> */}
						</a>
						{/* <form action="#" method="GET" className="hidden md:block md:pl-2">
								<label for="topbar-search" className="sr-only">
									Search
								</label>
								<div className="relative md:w-64 md:w-96">
									<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
										<svg
											className="w-5 h-5 text-gray-500 dark:text-gray-400"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<title>"Search icon</title>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											/>
										</svg>
									</div>
									<input
										type="text"
										name="email"
										id="topbar-search"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
										placeholder="Search"
									/>
								</div>
							</form> */}
					</div>

					<div className="flex items-center lg:order-2">
						<TenantSelector />
						<button
							onClick={() => {
								setShowUserMenu(!showUserMenu);
								setShowSidebar(false);
								handleUserMenuClick("UserMenu");
							}}
							type="button"
							className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
							id="user-menu-button"
							aria-expanded="false"
							data-dropdown-toggle="dropdown"
						>
							<span className="sr-only">Open user menu</span>
							<Avatar name={user?.name} />
						</button>{" "}
						{/* Dropdown menu */}
						<div
							className={`${
								showUserMenu ? "absolute" : "hidden"
							} right-4 top-10 z-40 my-4 w-56 text-base list-none bg-white divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl`}
							id="dropdown"
						>
							<div className="py-3 px-4  hover:cursor-default">
								<span className="block text-sm font-semibold text-gray-900 truncate dark:text-white">
									{user?.name}
								</span>
								<span className="block text-sm text-gray-900 dark:text-white">
									{practice?.practice_name}
								</span>
							</div>
							<div>
								<ul
									className="py-1 text-gray-700 dark:text-gray-300"
									aria-labelledby="dropdown"
								>
									<li>
										<Link
											to="legal/terms-of-service"
											className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
											onClick={() => {
												handleUserMenuClick("TermsOfService");
											}}
										>
											Terms of Service
										</Link>
									</li>
									<li>
										<Link
											to="legal/privacy-policy"
											className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
											onClick={() => {
												handleUserMenuClick("PrivacyPolicy");
											}}
										>
											Privacy Policy
										</Link>
									</li>
								</ul>
							</div>
							<ul
								className="py-1 text-gray-700 dark:text-gray-300"
								aria-labelledby="dropdown"
							>
								<li>
									<Link
										to="/logout"
										className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
										onClick={() => {
											handleUserMenuClick("SignOut");
										}}
									>
										Sign out
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>

			{/* Sidebar */}

			<aside
				className={classNames(
					{ "-translate-x-full": !showSidebar },
					{ "translate-x-0": showSidebar },
					"md:translate-x-0 fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform  bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700",
				)}
				aria-label="Sidenav"
				id="drawer-navigation"
			>
				<div className="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
					<ul className="space-y-2">
						{navElements.map((element) => (
							<li className="hover:cursor-pointer" key={element.label}>
								<Link
									to={element.path}
									className={classNames(
										{ hidden: element?.hidden },
										{ "bg-gray-50": element?.match },
										"flex hover:cursor-pointer items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group",
									)}
									onClick={() => handleNavClick(element)}
								>
									{element.icon}
									<span className="ml-3 hover:cursor-pointer">
										{element.label}
									</span>
								</Link>
							</li>
						))}
					</ul>
					<ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
						<li>
							<a
								href={`tel:${practicePhone}`}
								className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
								onClick={() => handleUserMenuClick("NavCallTopo")}
							>
								<PhoneCall weight="bold" size="20" />
								<span className="ml-3">Call Topo</span>
							</a>
						</li>
						<li>
							<div className="flex flex-1 items-center text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
								<button
									type="button"
									className="flex items-center  p-2 flex-1"
									onClick={() => {
										setShowModal(true);
										handleUserMenuClick("NavReferPatient");
									}}
								>
									<UserCirclePlus weight="bold" size="20" />
									<span className="ml-3">Refer a Patient</span>
								</button>
							</div>
						</li>
					</ul>
				</div>
				<div className="hidden absolute bottom-0 left-0 p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
					{/* Logout */}
					<div className="text-xs ml-2 align-top font-light">
						Beta version v1.0
					</div>
				</div>
			</aside>

			<main className="md:ml-64 h-full pt-16">
				<Breadcrumbs />
				{children}
				<FooterCta setShowModal={setShowModal} practicePhone={practicePhone} />
			</main>
		</div>
	);
};
