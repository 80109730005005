import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const Auth = () => {
	const { loginWithRedirect, isLoading, error, user } = useAuth0();

	useEffect(() => {
		if (!error) {
			loginWithRedirect();
		}
	}, [user, loginWithRedirect]);

	return (
		<>
			{error && <div>There was an error.</div>}
			{isLoading && <div className="p-4">Loading...</div>}
		</>
	);
};
