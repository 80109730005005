import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AxiosBase, createUrl, indexUrl } from "../shared/use-rest-query";
import { AuthorizationContext } from "../providers/authorization-provider";

export const Logout = () => {
	const { logout } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		logout({
			logoutParams: {
				returnTo: `${window.location.origin}/login`,
			},
		});
		navigate("/login");
	}, [logout, navigate]);

	return null;
};
