import { useQuery } from "@tanstack/react-query";
import { MetabaseEmbed } from "../components/metabase-embed";


export const Tasks = () => {
  return (
    <div className="p-4">
      <div className="flex justify-between items-center p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full text-3xl font-bold text-center my-4">
          Tasks
        </div>
      </div>
        <div className="w-full text-xl font-bold text-center my-4">
          Coming 🔜
        </div>
    </div>
  );
};
