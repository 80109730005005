import IframeResizer from "iframe-resizer-react";

interface Props {
	title: string;
	url: string;
	width?: string;
	height?: string;
	filters?: string;
}

export const MetabaseEmbed = (props: Props) => {
	if (Object.keys(props.url).length === 0) return <></>;
	return (
		<div className={`w-full min-h[${props.height}] relative`}>
			{/* This div exists because Metabase decided to put a non-configurable border (.EmbedFrame-header) inside the iFrame  */}
			{/* This plus the negative marginTop "paints over" that ugly line */}
			<div className="bg-white z-10 h-2 w-full absolute" />
			<IframeResizer
				warningTimeout={500}
				title={props.title}
				src={`${props.url}&font=Open Sans&hide_download_button=true`}
				height={props.height}
				width={props.width}
				style={{
					marginTop: "-2px",
					marginLeft: "-2px",
					height: "100vh",
					width: "1px",
					minWidth: "100%",
				}}
			/>
		</div>
	);
};
