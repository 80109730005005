import { useContext, useEffect, useState } from "react";
import { BellIcon } from "../components/icons/bell-icon";
import { StudyCard } from "../components/study-card";

import { useParams } from "react-router-dom";
import { StudyDetail } from "../components/study-detail";
import { useIndexQuery, useShowQuery } from "../shared/use-rest-query";
import { AuthorizationContext } from "../providers/authorization-provider";
import { useIsTabFocused } from "../shared/use-is-tab-focused";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";

export const Study = () => {
	const { practiceStudyId } = useParams();
	const { practice } = useContext(AuthorizationContext);
	const {
		data: { data: practiceStudy },
		isLoading,
		isError,
	} = useShowQuery("public/practice_studies", practiceStudyId);
	const studyId = practiceStudy?.study?.id;
	const isTabFocused = useIsTabFocused();
	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		if (studyId === undefined) {
			return;
		}
		event({
			eventName: `StudyView${isTabFocused ? "Start" : "End"}`,
			meta: {
				study_id: studyId,
			},
			getAccessTokenSilently,
		});
	}, [studyId, isTabFocused, getAccessTokenSilently]);
	useEffect(() => {
		return () => {
			event({
				eventName: "StudyViewEnd",
				meta: {
					study_id: studyId,
				},
				getAccessTokenSilently,
			});
		};
	}, [studyId, getAccessTokenSilently]);

	return (
		<div className="md:col-span-2">
			<StudyDetail practiceStudy={practiceStudy} />
		</div>
	);
};
