import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AxiosBase, createUrl, indexUrl } from "../shared/use-rest-query";
import { AuthorizationContext } from "../providers/authorization-provider";

export const AuthCallback = () => {
	// todo: if there's a redirect url set in context, redirect to that
	// else, redirect to /studies

	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { authorize } = useContext(AuthorizationContext);
	const navigate = useNavigate();

	if (!isAuthenticated) {
		return <></>;
	}
	return <>
		<Navigate to="/studies" />
	</>;
};
