import { type CustomFlowbiteTheme, Flowbite } from "flowbite-react";

export const customFlowbiteTheme: CustomFlowbiteTheme = {
	button: {
		color: {
			primary: "bg-red-500 hover:bg-red-600",
		},
	},
	select: {
		field: {
		}
	}
};

export const FlowbiteProvider = ({ children }) => {
	

	return (
			<Flowbite theme={{ theme: customFlowbiteTheme }}>
				{children}
			</Flowbite>
		);
	}
