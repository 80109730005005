import { Link } from "react-router-dom";
import { StudyCard } from "./study-card";
import Markdown from "markdown-to-jsx";
import { useShowQuery } from "../shared/use-rest-query";
import { classNames } from "../shared/classnames";
import parse from "html-react-parser";
import HTML2React from 'react-html-string-parser/HTML2React';

export const StudyDetail = (props) => {
	const { practiceStudy } = props;

	return (
		// make these cards that POP
		<div className="">
			<div className="p-2">
				<div className="space-y-4 rendered">
					<StudyCard practiceStudy={practiceStudy} hideCTA={true} />

					<h3 className="text-2xl font-bold mb-2 pl-2">Trial Overview</h3>

					<div className="h-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
						<div className=" text-gray-900">
							{practiceStudy?.study?.provider_summary && <HTML2React html={practiceStudy?.study?.provider_summary} />}
						</div>
					</div>

					<h3
						className={classNames(
							{ "": !practiceStudy?.study?.ie_criteria },
							"text-2xl font-bold mb-2 pl-2",
						)}
					>
						Key Inclusion / Exclusion Criteria
					</h3>
					<div
						className={classNames(
							{ "": !practiceStudy?.study?.ie_criteria },
							"h-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700",
						)}
					>
						<p className="mb-4 text-gray-900">
							For a full list of IE Criteria, see the protocol.
						</p>
						<div className="">{practiceStudy?.study?.ie_criteria && <HTML2React html={practiceStudy?.study?.ie_criteria} />}</div>
					</div>
					<h3 className="text-2xl font-bold mb-2 pl-2">Patient Pitch</h3>

					<div className="h-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
						{practiceStudy?.study?.how_you_can_help && <HTML2React html={practiceStudy?.study?.how_you_can_help} />}
					</div>
					<h2 className="pl-2 text-2xl font-bold mb-4">Trial Facts</h2>

					<div className="h-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
						<div className="mb-4 space-y-2 text-gray-900">
							<div className="text-lg font-bold">Compound</div>
							<div className="text-gray-900">
								{practiceStudy?.study?.compound}
							</div>
							<div className="text-lg font-bold pt-4">Study Specifics</div>
							<div className="text-gray-900">
								{practiceStudy?.study?.key_stats && <HTML2React html={practiceStudy?.study?.key_stats} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
