import { createContext, useContext, useEffect, useState } from "react";
import {
	AxiosBase,
	indexUrl,
	createUrl,
	showURL,
} from "../shared/use-rest-query";
import { useAuth0 } from "@auth0/auth0-react";

const defaultContext = {
	user: null,
	practice: null,
	isLoading: true,
	authorizedPractices: [],
	// setUser: (newUser) => {},
	// setPractice: (newPractice) => {},
	authorize: () => {},
	isAuthorized: false,
	changePractice: (id) => {},
};

export const AuthorizationContext = createContext(defaultContext);

export const useAuthorization = () => useContext(AuthorizationContext);

export const AuthorizationProvider = ({ children }) => {
	const { isLoading: isLoadingAuth0User, getAccessTokenSilently } = useAuth0();
	const isCurrentPractice = (practiceId) => {
		return context.practice?.id === practiceId;
	};

	const getAuthorizedPractices = async () => {
		const accessToken = await getAccessTokenSilently();
		const authorizedPracticesResponse = await AxiosBase.get(
			indexUrl("authorizations/practices"),
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		);
		const authorizedPractices = authorizedPracticesResponse?.data?.data;
		setContext({
			...context,
			authorizedPractices,
		});
		return authorizedPractices;
	};

	const authorize = async () => {
		const accessToken = await getAccessTokenSilently();
		const authHeaders = { Authorization: `Bearer ${accessToken}` };
		const authorizedPractices = await getAuthorizedPractices();
		const practiceId = localStorage.practiceId || authorizedPractices?.[0]?.id;
		const authorizationResponse = await AxiosBase.post(
			createUrl("authorizations"),
			{ id: practiceId },
			{ headers: authHeaders },
		);

		const currentUserResponse = await AxiosBase.get(
			indexUrl("authorizations"),
			{ headers: authHeaders },
		);
		localStorage.setItem(
			"practiceId",
			authorizationResponse?.data?.data?.practice_id,
		);

		setContext({
			...context,
			practice: authorizationResponse?.data?.data,
			user: currentUserResponse?.data?.data,
			isAuthorized: true,
			authorizedPractices,
		});
	};

	const changePractice = async (practiceId: string) => {
		localStorage.setItem("practiceId", practiceId);
		const accessToken = await getAccessTokenSilently();
		const authHeaders = { Authorization: `Bearer ${accessToken}` };

		if (localStorage.practiceId) {
			const authorizationResponse = await AxiosBase.get(
				indexUrl("authorizations"),
				{
					headers: authHeaders,
				},
			);
			const practiceResponse = await AxiosBase.get(
				showURL("public/practices", localStorage.practiceId),
				{
					headers: authHeaders,
				},
			);

			setContext({
				...context,
				user: authorizationResponse?.data?.data,
				isLoading: false,
				practice: practiceResponse?.data?.data,
			});
			return;
		}
	};

	const [context, setContext] = useState({
		...defaultContext,
		changePractice,
		authorize,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: No need to specify functions.
	useEffect(() => {
		const authorizeDefault = async () => {
			if (isLoadingAuth0User) return;

			// Prefer LS for default practiceId
			if (localStorage.practiceId) {
				await changePractice(localStorage.practiceId);
			}
			// Otherwise use the first authorized practice as a fallback
			else {
				const authorizedPractices = await getAuthorizedPractices();
				await changePractice(authorizedPractices[0]?.id);
			}
		};
		authorizeDefault();
		return () => {};
	}, [isLoadingAuth0User]);

	return (
		<AuthorizationContext.Provider value={context}>
			{children}
		</AuthorizationContext.Provider>
	);
};
