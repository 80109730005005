import { useContext, useEffect, useRef, useState } from "react";
import { ModalContext } from "../modal";
import { Controller, useForm } from "react-hook-form";
import { useCreate } from "../../shared/use-rest-query";
import { useAuth0 } from "@auth0/auth0-react";
import { uniqueIndications } from "../../shared/unique-indications";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TailwindDatePicker from "../../shared/tailwind-date-picker";
import dayjs from "dayjs";
import { event } from "../../shared/events";
import { ErrorMessage } from "@hookform/error-message";

export const ReferPatientModalReferralType = (props) => {
	const { setShowModal } = useContext(ModalContext);
	const { getAccessTokenSilently } = useAuth0();
	const { practiceStudies, setStep } = props;
	const closeModal = () => setShowModal(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { practiceStudyId } = useParams();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: { study_id: practiceStudies?.[0]?.study?.id },
	});

	useEffect(() => {
		if (!practiceStudyId || !setValue) return;

		const practiceStudy = practiceStudies.find(
			(practiceStudy) => practiceStudy.id === practiceStudyId,
		);
		if (practiceStudy) setValue("study_id", practiceStudy.study.id);

		return () => {};
	}, [practiceStudyId, practiceStudies, setValue]);

	function checkValidation(data) {
		let validationResult = true;
		if (!data.mrn && !data.full_name) {
			setError("full_name", {
				type: "manual",
				message: "Patient Name or MRN must be filled out.",
				shouldFocus: true,
			});
			setError("mrn", {
				type: "manual",
				message: "Patient Name or MRN must be filled out.",
			});
			validationResult = false;
		}

		if (!data.study_id) {
			setError("study_id", {
				type: "manual",
				message: "Study must be selected.",
			});
			validationResult = false;
		}

		if (validationResult) {
			clearErrors("full_name");
			clearErrors("mrn");
			clearErrors("study_id");
		}
		return validationResult;
	}

	const submitReferral = (formData) => {
		if (!checkValidation(formData)) return;
		setIsSubmitting(true);
		const nameParts = formData?.full_name?.split(" ");
		formData.given_name = nameParts?.[0];
		formData.family_name =
			nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
		if (formData?.dob !== undefined) {
			formData.dob = dayjs(formData.dob).format("YYYY-MM-DD");
		}

		const response = useCreate(
			"public/patient_referrals",
			formData,
			getAccessTokenSilently,
		);
		response.then(() => {
			setIsSubmitting(false);
			setStep("Success");
		});
		event({
			eventName: "Click",
			meta: {
				element: "ReferPatientSubmit",
			},
			getAccessTokenSilently,
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit(submitReferral)} className="">
				{/* Modal header */}
				<div className="flex items-center justify-between p-6 md:p-5 border-b rounded-t dark:border-gray-600">
					<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
						Make a Referral
					</h3>
					<button
						onClick={closeModal}
						type="button"
						className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						data-modal-hide="default-modal"
					>
						<svg
							className="w-3 h-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 14"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
							/>
						</svg>
						<span className="sr-only">Close modal</span>
					</button>
				</div>
				{/* Modal body */}
				<div className="p-4 md:p-5 space-y-4">
					<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 pr-2">
						Select the study this patient could be eligible for:
					</p>
					<div className="mb-4 relative">
						<select
							{...register("study_id")}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						>
							{/* TODO: refactor into component helper method */}
							{/* This builds the indication grouping.  it filters out groups that only are in enrolling status */}
							{uniqueIndications(practiceStudies)
								.sort()
								.filter(
									(indication) =>
										practiceStudies.filter(
											(practiceStudy) =>
												practiceStudy.study?.indication === indication &&
												["Enrolling", "Prescreening"].includes(
													practiceStudy.status,
												),
										).length > 0,
								)
								.map((indication) => (
									<optgroup key={indication} label={indication}>
										{/* This builds the selectable options */}
										{/* It only includes studies within the given indication */}
										{/* and are in enrolling status */}
										{practiceStudies
											.filter(
												(practiceStudy) =>
													practiceStudy.study?.indication === indication,
											)
											.filter((practiceStudy) =>
												["Enrolling", "Prescreening"].includes(
													practiceStudy.status,
												),
											)
											.map((practiceStudy) => (
												<option
													key={practiceStudy.study.nickname}
													value={practiceStudy.study.id}
												>
													{practiceStudy.study.nickname}
												</option>
											))}
									</optgroup>
								))}
						</select>

						<ErrorMessage
							errors={errors}
							name="study_id"
							render={({ message }) => (
								<p
									className={"absolute text-red-500 text-xs w-full text-right"}
								>
									{message}
								</p>
							)}
						/>
					</div>
					<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
						Add additional relevant information:
					</p>
					{/* Patient Form */}
					<div>
						<label
							htmlFor="email-address-icon"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
						>
							Patient Name
						</label>
						<div className="mb-4 relative">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
								<svg
									className="w-6 h-6 text-gray-600 dark:text-white"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									fill="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										fillRule="evenodd"
										d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
							<input
								{...register("full_name")}
								type="text"
								className={`bg-gray-50 border ${
									errors?.full_name ? "border-red-500" : "border-gray-300"
								} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
								placeholder="Optional"
							/>
							<ErrorMessage
								errors={errors}
								name="full_name"
								render={({ message }) => (
									<p
										className={
											"absolute text-red-500 text-xs w-full text-right"
										}
									>
										{message}
									</p>
								)}
							/>
						</div>

						<label
							htmlFor="email-address-icon"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
						>
							MRN
						</label>

						<div className="relative mb-4">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
								<svg
									className="w-6 h-6 text-gray-600 dark:text-white"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									fill="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										fillRule="evenodd"
										d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
							<input
								{...register("mrn")}
								type="text"
								// className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								className={`bg-gray-50 border ${
									errors?.mrn ? "border-red-500" : "border-gray-300"
								} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
								placeholder="Optional"
							/>

							<ErrorMessage
								errors={errors}
								name="mrn"
								render={({ message }) => (
									<p
										className={
											"absolute text-red-500 text-xs w-full text-right"
										}
									>
										{message}
									</p>
								)}
							/>
						</div>

						<label
							htmlFor="email-address-icon"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
						>
							DOB
						</label>
						<div className="relative mb-4">
							<Controller
								control={control}
								name="dob"
								render={({ field }) => (
									<TailwindDatePicker
										formatDensity="spacious"
										desktopModeMediaQuery=""
										value={field.value}
										inputRef={field.ref}
										onChange={(date) => {
											field.onChange(date);
										}}
									/>
								)}
							/>
						</div>

						<div className="relative mb-4">
							<label
								htmlFor="message"
								className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
							>
								Notes
							</label>
							<textarea
								{...register("notes")}
								id="message"
								rows={4}
								className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder="Write any additional information we should be aware of."
							/>
						</div>
					</div>
				</div>
				{/* Modal footer */}
				<div className="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
					<button
						type="submit"
						disabled={isSubmitting}
						data-modal-hide="default-modal"
						className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
					>
						Refer
					</button>
					<button
						data-modal-hide="default-modal"
						type="button"
						onClick={closeModal}
						className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
					>
						Cancel
					</button>
				</div>
			</form>
		</>
	);
};
