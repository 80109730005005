import React from "react";
import { Layout } from "./layout";
import { Studies } from "./pages/studies";
import { Study } from "./pages/study";
import { Finances } from "./pages/finances";
import { Metrics } from "./pages/metrics";
import { Auth } from "./pages/auth";
import { Logout } from "./pages/logout";
import { Tasks } from "./pages/tasks";
import { Calendar } from "./pages/calendar";
import { Link } from "react-router-dom";
import { AuthCallback } from "./pages/auth-callback";
import { TermsOfService } from "./pages/terms-of-service";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { HomeBreadcrumb } from "./components/home-breadcrumb";
import { StudyBreadcrumb } from "./components/study-breadcrumb";
import { AuthenticatedLayout } from "./authenticated-layout";
import { Login } from "./pages/login";

export const routes = [
	{
		path: "/terms-of-service",
		element: <TermsOfService />,
	},
	{
		path: "/privacy-policy",
		element: <PrivacyPolicy />,
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/",
		element: <AuthenticatedLayout />,
		breadcrumb: HomeBreadcrumb,
		children: [
			{
				index: true,
				element: <Auth />,
			},
			{
				path: "/callback",
				element: <AuthCallback />,
				breadcrumb: null,
			},
			{
				path: "/logout",
				element: <Logout />,
			},
			{
				path: "/studies",
				children: [
					{
						index: true,
						element: <Studies />,
						breadcrumb: "Studies",
					},
					{
						path: ":practiceStudyId",
						element: <Study />,
						breadcrumb: StudyBreadcrumb,
					},
				],
			},
			{
				path: "/legal",
				children: [
					{
						path: "terms-of-service",
						element: <TermsOfService />,
						breadcrumb: "Terms of Service",
					},
					{
						path: "privacy-policy",
						element: <PrivacyPolicy />,
						breadcrumb: "Privacy Policy",
					},
				],
			},
			{
				path: "/tasks",
				element: <Tasks />,
			},
			{
				path: "/finances",
				element: <Finances />,
				handle: {
					// you can put whatever you want on a route handle
					// here we use "crumb" and return some elements,
					// this is what we'll render in the breadcrumbs
					// for this route
					crumb: () => <Link to="/finances">Finances</Link>,
				},
			},
			{
				path: "/metrics",
				element: <Metrics />,
				handle: {
					// you can put whatever you want on a route handle
					// here we use "crumb" and return some elements,
					// this is what we'll render in the breadcrumbs
					// for this route
					crumb: () => <Link to="/metrics">Metrics</Link>,
				},
			},
			{
				path: "/calendar",
				element: <Calendar />,
				handle: {
					// you can put whatever you want on a route handle
					// here we use "crumb" and return some elements,
					// this is what we'll render in the breadcrumbs
					// for this route
					crumb: () => <Link to="/calebdar">Calendar</Link>,
				},
			},
		],
	},
];
