import React from "react"
import ContentLoader from "react-content-loader"

const CardLoader = (props) => (
  <ContentLoader
    speed={1}
    className="flex rounded-lg"
    viewBox="0 0 360 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect radius={10} x="5" y="14" rx="10" ry="10" width="359" height="180" />
  </ContentLoader>
)

export default CardLoader

