import { upperCase } from "change-case-all"
import { nameToInitials } from "../shared/text-utils"

const utahSrc = new URL("../assets/utah.png", import.meta.url)

interface Props {
  initials?: string
  utah?: boolean
  className?: string
  name?: string
}

export function Avatar(props: Props) {
  const { name, initials, utah } = props
  const derivedInitials = nameToInitials(name)
  const upperCaseInitials = initials
    ? upperCase(initials)
    : upperCase(derivedInitials)

  if (utah) return (
    <img
								className="w-8 h-8 rounded-full"
								src={utahSrc}
								alt=""
								title="Johnny Utah"
							/>
  )
  if (initials || derivedInitials)
    return (
      <div
        title={initials || derivedInitials}
        className={`bg-topo-navy z-100 text-white select-none flex justify-center items-center border-1 rounded-full h-8 w-8 pt-[0.05rem] pl-[0.02rem] text-sm dark:border-gray-700 cursor-pointer ${props.className}`}
      >
        <p className="z-100">{upperCaseInitials}</p>
      </div>
    )
  return (
    <div
      title={"Unknown User"}
      className={`bg-topo-navy z-100 text-white select-none flex justify-center items-center border-1 rounded-full h-8 w-8 pt-[0.05rem] pl-[0.02rem] text-sm dark:border-gray-700 cursor-pointer ${props.className}`}
    >
      <p className="z-100">?</p>
    </div>
  )
}
