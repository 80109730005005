import { MetabaseEmbed } from "../components/metabase-embed";
import { useShowQuery } from "../shared/use-rest-query";

export const Finances = () => {
	const {
		data: { data: metabaseURL },
	} = useShowQuery("public/metabase_iframes", "provider_dashboard_finance", {
		params: "bordered=false",
	});
	return (
		<div className="h-full p-2">
			<div className="bg-white shadow-md rounded-lg p-1 max-w-xl md:max-w-full">
				{/* <div className="p-4 text-3xl font-bold">Finances</div> */}
				{/* TODO: Total earned income by study do rows 10- */}
				<MetabaseEmbed title="Finances" url={metabaseURL} />
			</div>
		</div>
	);
};
