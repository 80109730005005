export function customPracticeStudySort(practiceStudies) {
	const statusOrder = {
		Enrolling: 1,
		Prescreening: 2,
		Contracting: 3,
		Maintenance: 4,
		Closed: 5,
	};

	practiceStudies.sort((a, b) => {
		// Compare by status
		const statusA = a.status;
		const statusB = b.status;
		const orderA = statusOrder[statusA] || Object.keys(statusOrder).length + 1; // Default to last if status is not found
		const orderB = statusOrder[statusB] || Object.keys(statusOrder).length + 1; // Default to last if status is not found

		if (orderA !== orderB) {
			return orderA - orderB;
		}

		// Compare by study.indication
		const indicationA = a.study?.indication || "";
		const indicationB = b.study?.indication || "";
		if (indicationA < indicationB) {
			return -1;
		}
		if (indicationA > indicationB) {
			return 1;
		}

		// Compare by study.nickname
		const nicknameA = a.study?.nickname || "";
		const nicknameB = b.study?.nickname || "";
		if (nicknameA < nicknameB) {
			return -1;
		}
		if (nicknameA > nicknameB) {
			return 1;
		}

		return 0;
	});

	return practiceStudies;
}
