import React, { useEffect } from "react";
import { TermsOfServiceContent } from "./terms-of-service/terms-of-service-content";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";
import { useIsTabFocused } from "../shared/use-is-tab-focused";

export const TermsOfService = () => {
	const isTabFocused = useIsTabFocused();
	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		event({
			eventName: `TermsOfServiceView${isTabFocused ? "Start" : "End"}`,
			getAccessTokenSilently,
		});
	}, [isTabFocused, getAccessTokenSilently]);
	useEffect(() => {
		return () => {
			event({
				eventName: "TermsOfServiceViewEnd",
				getAccessTokenSilently,
			});
		};
	}, [getAccessTokenSilently]);
	return (
		<div className="flex justify-center pt-10">
			<div className="w-2/3">
				<TermsOfServiceContent />
			</div>
		</div>
	);
};
