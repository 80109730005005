
export const PrivacyPolicyContent = () => {
	return (
		<div>
			<div className="p-6 text-gray-800">
				<h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
				<p className="mb-4">
					Topography Health, Inc. (collectively, “Topography” or “we”) takes
					privacy seriously.
				</p>
				<p className="mb-4">
					Included in this privacy notice (the “Privacy Notice”) is important
					information about how we, together with any subsidiaries or affiliated
					entities (collectively, “Affiliates”), use the data you provide to us
					through using our website, available at{" "}
					<a
						href="https://www.jointopo.com"
						className="text-blue-600 hover:underline"
					>
						https://www.jointopo.com
					</a>{" "}
					and related websites, including{" "}
					<a
						href="http://investigators.jointopo.com"
						className="text-blue-600 hover:underline"
					>
						investigators.jointopo.com
					</a>{" "}
					(the “Site”). If you also use Topography's products and services
					(collectively, the “Services”), your use of those Services is also
					governed by the relevant Terms of Service, as well as this Privacy
					Notice.
				</p>
				<p className="mb-4">
					This Privacy Notice describes Topography's collection, processing of
					certain information, including personally identifiable information
					(“Personal Information”), that we collect in connection when you use
					our Site or Services. This Privacy Notice also covers any other data
					and content that is accessed, utilized, or generated in connection
					with this Site. By visiting or using any Site or Service, you are
					providing consent to the collection, use, and disclosure of your
					Personal Information and other data in accordance with this Privacy
					Notice.
				</p>
				<p className="mb-4">
					Please note that this Privacy Notice reflects the rules, regulations,
					and policies in place at the time in multiple jurisdictions, and may
					be updated as applicable laws and circumstances change and we reserve
					the right to amend this Privacy Notice from time to time. We encourage
					you to review this Privacy Notice periodically. Any changes to the
					Privacy Notice will become effective when we post the revised terms on
					the Site.
				</p>
				<p className="mb-4 font-bold">
					YOUR USE OF THE SITE FOLLOWING THESE CHANGES MEANS THAT YOU ACCEPT THE
					REVISED PRIVACY NOTICE.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Who is Topography Health?
				</h2>
				<p className="mb-4">
					Topography is a clinical trials technology business that builds tools
					and provides services that enable more physicians to participate in
					research.
				</p>
				<p className="mb-4">
					You can reach our privacy team by contacting:{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
				</p>
				<p className="mb-4">
					Topography is responsible for your Personal Information when you use
					this Site. Specifically, we are the “Controller” of your data under
					the General Data Protection Regulation (“GDPR”) if you live in the
					European Union, European Economic Area, or United Kingdom, and we are
					the “Business” for purposes of the California Consumer Privacy Act
					(“CCPA”) if you live in California.
				</p>
				<p className="mb-4">
					Topography is located in the United States and hosts our Site in the
					United States as well. If you live in a different country, you should
					be aware that your use of the Site constitutes explicit consent to
					transfer your Personal Information out of your country and to the
					United States.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					What types of personal information does Topography collect?
				</h2>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Information You Provide
				</h3>
				<p className="mb-4">
					You may provide us with your Personal Information on our Site. For
					example, you may provide us with contact information, including your
					name, email address, mailing address, and phone number, if you are
					interested in an employment opportunity or wish to be on our mailing
					lists.
				</p>
				<p className="mb-4">
					You may also enter in your Personal Information through other
					channels, including in emails, telephone calls, surveys, and other
					correspondence to register your interest in our Services and to enable
					us to contact you or your organization. You may also provide Personal
					Information when you attend one of our events or when you speak with
					our representatives or experts.
				</p>
				<p className="mb-4">
					In each case, we will ask for your permission to contact you, and you
					may withdraw that consent at any time.
				</p>
				<p className="mb-4">
					You may also elect to input individually identifiable information of
					patients of your healthcare organization in furtherance of review
					preparatory to research in accordance with the Terms of Use.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Information From Our Website
				</h3>
				<p className="mb-4">
					When you visit the Site, we may also collect information through your
					browser or device. We automatically obtain information about your
					visits to the Site such as browser and device information, usage data,
					IP address, geographical location, referral source, and length of
					visit and pages viewed.
				</p>
				<p className="mb-4">
					We use this data to create and analyze general statistics regarding
					use of the Site. When available, we sometimes link the data collected
					from your device to other Personal Information such as name, email
					address, address, and phone number.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Information from Third Parties
				</h3>
				<p className="mb-4">
					We partner with third parties that help us analyze user activity to
					monitor and improve our website, to help identify and recruit
					potential employment candidates, and to provide support to you. Our
					third-party partners may use cookies or similar technologies to help
					us do that.
				</p>
				<p className="mb-4">
					For marketing and employment purposes, we may obtain your Personal
					Information from other sources, such as public databases, joint
					marketing partners, and social media platforms.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">Cookies</h3>
				<p className="mb-4">
					We also use cookies in connection with our Site, as do our service
					providers like AWS and Google Analytics. Cookies are small text files
					placed on your hard drive by a web page server, which collect
					information about user actions on the websites visited. Information
					collected by cookies can include your device settings, browsing
					activities, and other information that may be useful to curating your
					experience on the Site.
				</p>
				<p className="mb-4">
					You have options to avoid the use of cookies. For example, you can
					disable cookies on your browser and on third-party sites, decline to
					opt in to cookies on our Site, and/or delete cookies on your browsers.
					You can obtain up-to-date information about blocking and deleting
					cookies by visiting the privacy pages of your browser(s). However,
					blocking some or all cookies from your browser may have a negative
					impact upon the usability of many websites, including our Site.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					How does Topography use personal information?
				</h2>
				<h3 className="text-xl font-semibold mt-4 mb-2">Serving You</h3>
				<p className="mb-4">
					We may use your Personal Information to enable you to obtain
					information about our Services and products, use our Services, acquire
					updates about the Site or Services, participate in surveys or
					questionnaires posted on our Site, and learn information about our
					other services, programs, and opportunities that may be of interest to
					you. We also use your Personal Information to respond to your
					requests.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Operating the Sites and Providing the Services
				</h3>
				<p className="mb-4">
					We may use your Personal Information to operate and improve the Site
					and Services, to maintain the integrity of our network, and to address
					potential security issues. We may also utilize Personal Information
					for other valid, internal business purposes, such as for research and
					development.
				</p>
				<p className="mb-4">
					Personal Information is also used for analytics purposes, which
					provides information on customer habits and preferences, through third
					party services like Google Analytics. For more information on Google
					Analytics, including how to opt out from certain data collection,
					please visit{" "}
					<a
						href="https://www.google.com/analytics"
						className="text-blue-600 hover:underline"
					>
						https://www.google.com/analytics
					</a>
					.
				</p>

				<h3 className="text-xl font-semibold mt-4 mb-2">
					Creating Aggregated Data
				</h3>
				<p className="mb-4">
					We may use Personal Information collected from various sources to
					create aggregated data (“Aggregated Data”) so that we can maintain or
					improve the Sites and Services and for other internal business
					purposes related to marketing, research, development, analysis and
					security.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">Other Uses</h3>
				<p className="mb-4">
					We may also use or disclose your Personal Information as necessary or
					appropriate for valid legal purposes, including laws outside your
					county of residence. For example, we may use Personal Information to
					comply with legal process or inquiries, to enforce our terms and
					conditions and legal rights, and to protect our operations or those of
					any of our partners or Affiliates.
				</p>
				<p className="mb-4">
					We may disclose your Personal Information to our third-party
					professional advisors who are subject to contractual or other binding
					confidentiality requirements, insofar as reasonably necessary for
					internal business purposes such as managing risks, obtaining expert
					advice, or handling legal claims.
				</p>
				<p className="mb-4">
					For the avoidance of doubt, we do not sell Personal Information, as
					“sale” as defined by CCPA.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					How does Topography share personal information?
				</h2>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Affiliates & Other Related Entities
				</h3>
				<p className="mb-4">
					We may provide your Personal Information to Affiliates. Unless
					prohibited by applicable law, Topography and its Affiliates may share
					Personal Information collected through any Site or Service and may
					contact you directly. Other related entities, outside consultants, and
					investors may also have access to Personal Information for due
					diligence and other limited business purposes.
				</p>
				<p className="mb-4">
					In addition, if we are subject to a merger, sale, reorganization, or
					other similar transaction, your Personal Information may be
					transferred as part of the transaction, and the surviving entity and
					its affiliates may use your Personal Information under the terms of
					their own privacy policies which may differ from this Privacy Notice.
					You will be notified via email and/or a notice on our Site of any
					change in our ownership and uses of your Personal Information, and
					choices you may have regarding your Personal Information.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">Service Providers</h3>
				<p className="mb-4">
					We employ service providers who assist the Company in running the
					Site, servicing you, conducting our business, recruiting employees and
					consultants, and managing internal business operations. Our service
					providers are subject to contractual requirements to uphold the
					confidentiality of your Personal Information, to maintain reasonable
					security measures to safeguard the privacy, security, and integrity of
					your Personal Information, and to use your Personal Information only
					as we instruct them. These outside parties have access to Personal
					Information only as reasonably necessary to perform their functions
					for us, and are subject to the requirements in this Privacy Notice and
					applicable laws, in addition to the contractual requirements
					referenced above.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					International Data Transfers
				</h3>
				<p className="mb-4">
					Our servers are in the United States. As such, your Personal
					Information may be transferred from your country to the United States.
					By interacting with the website, you consent for your data to be
					transferred to the United States. You may withdraw your consent by
					contacting us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					.
				</p>
				<p className="mb-4">
					Your Personal Data may be stored and processed in any country where we
					have facilities or in which we engage service providers. By using the
					Sites or disclosing information to us you consent to the transfer of
					information to countries outside of your country of residence. These
					countries may have different data protection rules than those of your
					country of residence or the country in which you were located when you
					initially provided the information.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">Third Party Sites</h3>
				<p className="mb-4">
					Except in connection with the above, we do not provide your Personal
					Information to external websites. However, the Site may contains links
					to other websites on the Internet and your Personal Information may be
					captured by those third-party sites. The information and data privacy
					practices of other websites are governed by the privacy statements of
					those websites and not by this Privacy Notice. Please review the
					privacy policies found on such other websites, services, and
					applications to understand how your information may be collected and
					used.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					What rights do individuals have over personal information?
				</h2>
				<p className="mb-4">
					Topography respects your rights to your Personal Information and
					complies with applicable laws and industry standards that govern your
					privacy. The specifics of your rights may vary based on where you
					live, but Topography is committed to offering you the following
					rights:
				</p>
				<ul className="list-disc list-inside mb-4">
					<li>
						Access - you have the right to access your Personal Information;
					</li>
					<li>
						Complain - you have the right to complain about how your Personal
						Information is handled;
					</li>
					<li>
						Correct - you have the right to correct any Personal Information
						that is inaccurate;
					</li>
					<li>
						Delete - you have the right to delete your Personal Information,
						subject to some limitations;
					</li>
					<li>
						Inquire - you have the right to inquire or complain about how your
						Personal Information is handled;
					</li>
					<li>
						Object - you have the right to object to some data processing of
						your Personal Information, including the right to object to
						marketing contacts;
					</li>
					<li>
						Obtain - you have the right to obtain your Personal Information in a
						portable format;
					</li>
					<li>
						Withdraw - you have the right to withdraw consent for us to process
						your Personal Information at any time.
					</li>
				</ul>
				<p className="mb-4">
					Please contact us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					How does Topography keep information secure?
				</h2>
				<p className="mb-4">
					Topography takes data security seriously. We work with expert
					third-party compliance consultants to ensure your Personal Information
					and data input during your use of the Services is kept secure and
					confidential. We utilize industry standard security programs and
					technical safeguards to help prevent the unauthorized use or
					disclosure of Personal Information and data input and to uphold
					integrity of our data.
				</p>
				<p className="mb-4">
					However, there is still the chance that an unexpected security breach
					would lead to the unauthorized access, disclosure, or use of the
					information subject to this Privacy Notice. If we become aware of any
					such incident involving such information, we will work with the
					relevant authorities to report and work to resolve the breach, in
					accordance with applicable law. We may also let you know by posting
					details of the breach to the Site or by email, if available.
				</p>
				<p className="mb-4">
					Please contact us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>{" "}
					if you have any questions or concerns about how we are keeping
					information secure.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					How long does Topography keep information?
				</h2>
				<p className="mb-4">
					We retain Personal Information and information collected during use of
					the Site for as long as necessary to fulfill the purposes in the
					Privacy Notice and as long as we have a lawful basis to do so. In the
					event of any deletion request, we aim to purge the relevant data
					within 30 days, unless exceptions apply.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					What choices do individuals have over personal information?
				</h2>
				<p className="mb-4">
					You can always opt not to disclose information. However, if you elect
					to do so, we may be limited in responding to your inquiry or providing
					services to you.
				</p>
				<p className="mb-4">
					You may opt-out of receiving marketing messages from Topography or our
					Affiliates by sending an email to{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					, or by unsubscribing through the “unsubscribe” or “opt-out link” in
					an email. We will try to comply with your request(s) as soon as
					reasonably practicable. Please note that if you opt-out of receiving
					marketing-related emails from us, we may still send you communications
					related to your account or Services.
				</p>
				<p className="mb-4">
					You may also access, correct, remove, or limit the use or disclosure
					of your Personal Information. To do so, please notify us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>{" "}
					so that we may review and respond to your request in accordance with
					applicable law. For your protection, we may need to verify your
					identity before implementing your request. We will respond to your
					request as soon as practicable or as required under applicable law.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					What special provisions apply to my geographic area?
				</h2>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					For visitors in the European Union, European Economic Area, or United
					Kingdom
				</h3>
				<p className="mb-4">
					Where applicable, you may exercise any of your rights under GDPR by
					contacting us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					. To limit collection by means of cookies, you may also adjust your
					cookie settings in your browser.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					For visitors from Canada
				</h3>
				<p className="mb-4">
					You may contact Topography's Privacy Officer at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					For visitors from California
				</h3>
				<p className="mb-4">
					California consumers have special rights under CCPA.
				</p>
				<p className="mb-4">
					For purposes of this section “Personal Information” has the meaning
					given in CCPA and includes the following:
				</p>
				<ul className="list-disc list-inside mb-4">
					<li>
						Identifiers - this includes personally identifiable information,
						such as name, e-mail address, mailing address, and phone number,
						which we collect directly from you or from third party sources.
					</li>
					<li>
						Commercial Information - this includes information related to our
						products or your interest in them, which we collect directly from
						you or from third party sources.
					</li>
					<li>
						Networking Data - this includes Internet or network information,
						such as browsing and search history, which we collect directly from
						your device.
					</li>
					<li>
						Geolocation Data - this includes IP address, which we collect
						directly from your device.
					</li>
					<li>
						Educational Data - this includes your educational background, which
						we collect directly from you.
					</li>
					<li>
						Financial Information - this includes financial and payment data,
						which we do not collect from our Site.
					</li>
					<li>
						Other - this includes other personal data when you interact with us
						online, by phone or mail in the context of receiving help,
						participating in customer surveys or contests, or other
						communications.
					</li>
					<li>
						Inferences - this includes inferences and advertising profiles drawn
						from the above information.
					</li>
				</ul>
				<p className="mb-4">
					As described in our Privacy Notice, we typically receive this Personal
					Information directly from you, our Site, or third-party partners. We
					also obtain this information directly from public sources, social
					networks, government organizations, professional associations, or
					other third-party sources like employment websites. We may also obtain
					this information indirectly.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Sales in the Past 12 Months
				</h3>
				<p className="mb-4">
					We do not sell Personal Information and neither Topography nor its
					Affiliates have sold any Personal Information in the previous twelve
					months, as defined by the CCPA.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Disclosures in the Past 12 Months
				</h3>
				<p className="mb-4">
					We have disclosed Personal Information in the last twelve months for
					the following purposes:
				</p>
				<ul className="list-disc list-inside mb-4">
					<li>Recruiting</li>
					<li>Security</li>
					<li>Compliance</li>
					<li>Internal research</li>
					<li>Quality</li>
				</ul>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Business and Commercial Purposes in the Past 12 Months
				</h3>
				<p className="mb-4">
					We have used Personal Information in the last twelve months for the
					following business and/or commercial purposes:
				</p>
				<ul className="list-disc list-inside mb-4">
					<li>Recruiting</li>
					<li>Security</li>
					<li>Compliance</li>
					<li>Internal research</li>
					<li>Quality</li>
					<li>Marketing</li>
				</ul>
				<h3 className="text-xl font-semibold mt-4 mb-2">CCPA Rights</h3>
				<p className="mb-4">
					Under the CCPA, California residents have certain rights with respect
					to their Personal Information. These include, without limitation, the
					right to know, the right to request information regarding disclosures
					for direct marketing purposes, the right to delete, and the right to
					non-discrimination.
				</p>
				<ul className="list-disc list-inside mb-4">
					<li>
						Right to Know - Once we are able to confirm your identity and
						authority to make a right to know request, we can disclose to you
						the categories of information collected, the specific pieces of
						information obtained, sources of such information, our business
						purposes for collecting or disclosing that information, and the
						third-party recipients (if any) of your information. You may make
						such a request no more than twice in one calendar year.
					</li>
					<li>
						Right to Delete - You can also request that we delete your
						information. We will comply with such deletion requests, except as
						required to complete the transaction for which the Personal
						Information was provided, as needed for security and legal purposes,
						or for limited permitted internal business purposes (including as
						needed to provide the Services). If successfully processed, we will
						delete or deidentify the applicable Personal Information from our
						records and will direct our Affiliates and service providers to take
						similar action.
					</li>
					<li>
						Non-Discrimination - We do not discriminate against individuals that
						exercise their privacy rights under CCPA. We will not deny you the
						access to the Site or Services, charge you different prices, or
						provide a different level of service to you. However, we may not be
						able to offer full website functionality if you disable access to
						cookie or analytical processes.
					</li>
				</ul>
				<p className="mb-4">
					Although we do not sell Personal Information, if our practices changed
					or were found to constitute a sale, we would offer you the opportunity
					to opt out of the sale. We would also update our Privacy Notice
					accordingly.
				</p>
				<h3 className="text-xl font-semibold mt-4 mb-2">
					Exercising CCPA Rights
				</h3>
				<p className="mb-4">
					You or your authorized agent may contact us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>{" "}
					to exercise your rights under CCPA and this Privacy Notice. Note that
					to protect your Personal Information and privacy, we will verify your
					identity prior to processing your request.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					Does Topography collect information about children?
				</h2>
				<p className="mb-4">
					Our Site and Services are not intended for children aged 16 or
					younger. We do not knowingly collect or request any information about
					children on our Site. If you believe that we have inadvertently
					collected Personal Information about a child under 16, please contact
					us at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>{" "}
					so that we may delete the information.
				</p>
				<h2 className="text-2xl font-semibold mt-6 mb-2">
					How do individuals find out more?
				</h2>
				<p className="mb-4">
					If you have any concerns about the Privacy Notice or your legal rights
					herein, you may contact our privacy team at{" "}
					<a
						href="mailto:privacy@jointopo.com"
						className="text-blue-600 hover:underline"
					>
						privacy@jointopo.com
					</a>
					.
				</p>
			</div>
		</div>
	);
};
