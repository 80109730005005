import { NavLink, useMatches } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "../routes";

export const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: false })
	
	return (
		<>
			<nav className="flex pt-2 pb-0.5 md:pb-2 pl-3 md:pl-2" aria-label="Breadcrumb">
				<ol className="inline-flex space-x-1 md:space-x-2 rtl:space-x-reverse items-baseline">
					{breadcrumbs.map(({ match, breadcrumb }, index) => (
						<li key={match.pathname}>
							<div className="flex items-center">
								{index > 0 && (
									<svg
										className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 6 10"
									>
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M1 9l4-4-4-4"
										/>
									</svg>
								)}
								<div className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
								<NavLink to={match.pathname}>
									{breadcrumb}
								</NavLink>
								</div>
							</div>
						</li>
					))}
				</ol>
			</nav>
		</>
	);
};

