import {
	Building,
	Clock,
	Dna,
	MagnifyingGlass,
	Microscope,
	Newspaper,
	Paperclip,
} from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { classNames } from "../shared/classnames";
import { HiInformationCircle } from "react-icons/hi";
import { useEffect } from "react";
import { event } from "../shared/events";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const StudyCard = (props) => {
	const { practiceStudy, hideCTA } = props;
	const { getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();
	const handleStudyCardClick = async (practiceStudy) => {
		event({
			eventName: "Click",
			meta: {
				study_id: practiceStudy?.study?.id,
				element: "StudyCard",
			},
			getAccessTokenSilently,
		});
	};
	if (!practiceStudy) return null;
	return (
		<Link
			to={`/studies/${practiceStudy.id}`}
			className=" h-full flex justify-between pb-2"
			onClick={() => handleStudyCardClick(practiceStudy)}
		>
			<div className="h-full w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex  items-center">
				<div className="w-1/4 sm:w-1/4 flex justify-center pr-6">
					{practiceStudy?.study?.sponsor?.logo_url ? (
						<img
							className="block"
							src={practiceStudy?.study?.sponsor?.logo_url}
							alt="Sponsor logo"
							style={{ width: "50%", objectFit: "contain", minWidth: "100px" }}
						/>
					) : (
						<Microscope size="50" />
					)}
				</div>
				<div className="w-3/4 h-full items-center">
					<div className="h-full items-start flex flex-col">
						{/* <div className="hidden text-blue-700 text-xs space-x-1 border border-blue-700 rounded-md px-6 py-1 items-center justify-center h-6 my-2">
							<Clock />
							<div>{practiceStudy?.status}</div>
						</div> */}
						<div className="flex items-center hover:cursor-default text-xl tracking-tight text-orange-500">
							{/* <div className=""> */}
							{/* <div className="text-orange-500">
									<Dna className="" />
								</div> */}
							{/* <HiInformationCircle size={15} /> */}
							<div className="font-semibold">
								{practiceStudy?.study?.indication}
							</div>
							{/* </div> */}
						</div>
						<div className="flex items-center space-x-1">
							<div>
								<Paperclip />{" "}
							</div>
							<h5 className="text-xl font-normal tracking-tight text-gray-900 dark:text-white pr-2">
								{practiceStudy?.study?.nickname ||
									practiceStudy?.study?.abbreviation ||
									"Study"}
							</h5>
						</div>
						{/* <div className="xl:flex xl:hidden hidden text-blue-700 text-xs space-x-1 border border-blue-700 rounded-md px-6 py-1 items-center h-6 mt-1.5">
							<Clock />
							<div>{practiceStudy?.status}</div>
						</div> */}

						<div className="flex items-center space-x-2 font-normal text-sm text-gray-800 dark:text-gray-400 ">
							<div>
								<Building />{" "}
							</div>
							<div className="hover:cursor-default">
								{practiceStudy?.study?.sponsor.name}
							</div>
						</div>

						<div className="flex text-sm text-gray-800 items-center space-x-2 rounded-md py-0.5 overflow-hidden ">
							<MagnifyingGlass />
							<div className="hover:cursor-default">
								{practiceStudy?.study?.study_type}
							</div>
						</div>
						<div className="flex text-blue-700 text-xs space-x-1 border border-blue-700 rounded-md px-6 py-1 items-center h-6 mt-1.5">
							<Clock />
							<div>{practiceStudy?.status || "Unknown"}</div>
						</div>
					</div>

					{/* <Link
						to={`/studies/${practiceStudy.id}`}
						className={classNames(
							{ hidden: true },
							"inline-flex  self-start items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
						)}
					>
						Read more
						<svg
							className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 10"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M1 5h12m0 0L9 1m4 4L9 9"
							/>
						</svg>
					</Link> */}
				</div>
			</div>
		</Link>
	);
};
