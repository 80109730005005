import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderCustom = ({ children }) => {
	const domain = process.env.AUTH0_DOMAIN;
	const clientId = process.env.AUTH0_CLIENT_ID;
	const audience = process.env.AUTH0_AUDIENCE;
	const callbackPath = "/callback";
	const scope = "openid email profile offline_access";

	if (!(domain && clientId && audience)) {
		return null;
	}

	const onRedirectCallback = (appState) => {
		window.location = appState?.returnTo
			? appState.returnTo
			: window.location.pathname;
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				scope: scope,
				audience: audience,
				redirect_uri: window.location.origin + callbackPath, // this is only used for domain verification, must be present in Auth0  onfig
			}}
			useRefreshTokensFallback={true}
			onRedirectCallback={onRedirectCallback} // this is the magic
			useRefreshTokens={true}
			cacheLocation="localstorage" // this is because we're hosting on amplify, serverless. if we had a traditional webserver, we could set HTTPOnly cookie
		>
			{children}
		</Auth0Provider>
	);
};
