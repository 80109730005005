import { useAuth0 } from "@auth0/auth0-react";
import { QueryCache, QueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";

export const clearCacheQuery = () => {
	const queryCache = new QueryCache();
	queryCache.clear();
};

export const AxiosBase = axios.create({
	withCredentials: true,
});

export const fetcher = async (
	url = "",
	getAccessTokenSilently = undefined,
	timeout = 30000,
) => {
	const accessToken = await getAccessTokenSilently();
	const controller = new AbortController();
	const timeoutFn = setTimeout(() => {
		controller.abort();
	}, timeout);
	const response = await AxiosBase.get(url, {
		signal: controller.signal,
		headers: { Authorization: `Bearer ${accessToken}` },
	});
	clearTimeout(timeoutFn);
	return response.data;
};

export const useIndexQuery = (resource, params = null, options = {}) => {
	// To get an access token, this must be called from within a component where it has context of auth provider
	const { getAccessTokenSilently } = useAuth0();

	if (resource.includes("?")) {
		throw new Error(
			`Resource ${resource} contains a question mark.  Please include query params in the params object for the best caching experience.`,
		);
	}

	const queryFn = async () => {
		const url = `${indexUrl(resource)}${params ? `?${params}` : ""}`;
		const data = await fetcher(url, getAccessTokenSilently);
		return data;
	};

	return useQuery({
		queryKey: [resource, params],
		queryFn: queryFn,
		initialData: { data: [] },
	});
};

// TODO
// export const useIndexInfinite = (resource, params=null, options={}) => {}

export const useShowQuery = (
	resource,
	id,
	options = {},
	useQueryOptions = {},
) => {
	if (id === undefined) return;
	// To get an access token, this must be called from within a component where it has context of auth provider
	const { getAccessTokenSilently } = useAuth0();

	const queryFn = async () => {
		const url = `${showURL(resource, id)}${
			options?.params ? `?${options?.params}` : ""
		}`;
		const data = await fetcher(url, getAccessTokenSilently);
		return data;
	};

	return useQuery({
		queryKey: [resource, id],
		queryFn: queryFn,
		initialData: { data: {} },
		...useQueryOptions,
	});
};

export function showURL(resource, id) {
	return `${process.env.API_URL}/${resource}/${id}`;
}

export function indexUrl(resource) {
	return `${process.env.API_URL}/${resource}`;
}

export function createUrl(resource) {
	return indexUrl(resource);
}

export function updateUrl(resource, id) {
	return showURL(resource, id);
}

export const useCreate = async (resource, data, getAccessTokenSilently) => {
	const accessToken = await getAccessTokenSilently();
	return await AxiosBase.post(createUrl(resource), data, {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
};

export const useUpdate = async (resource, id, data, getAccessTokenSilently) => {
	const accessToken = await getAccessTokenSilently();
	return await AxiosBase.patch(updateUrl(resource, id), data, {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
};

export const useDelete = async (resource, id, getAccessTokenSilently) => {
	const accessToken = await getAccessTokenSilently();
	return await AxiosBase.delete(updateUrl(resource, id), {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
};
