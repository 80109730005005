import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowSquareOut, PlusCircle } from "@phosphor-icons/react";

export const Login = () => {
	const { loginWithRedirect } = useAuth0();
	const logoFullColor = new URL(
		"../assets/logo-full-color.svg",
		import.meta.url,
	).toString();
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				padding: "4rem", // add space around
			}}
		>
			<div className="max-w-sm min-w-max w-1/4 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center flex flex-col justify-center">
				<a href="/" className="flex items-center justify-between mr-4 mb-8">
					{" "}
					{/* Add space below the logo */}
					<img alt="logo" src={logoFullColor} className="w-2/3 mx-auto" />{" "}
					{/* Center logo */}
				</a>
				<h1 className="mb-4 text-center">Welcome to Our Site</h1>{" "}
				{/* Center title and add space below */}
				<button
					type="button"
					className="flex w-full items-center rounded shadow-lg justify-center text-center p-4 bg-topo-blue text-white dark:bg-gray-800 space-x-2 hover:cursor-pointer hover:bg-blue-800 mt-4"
					onClick={() => loginWithRedirect()}
				>
					<div>Login to your account</div>
					<div className="bg-transparent rounded-2xl">
						<ArrowSquareOut color="white" />
					</div>
				</button>
			</div>
		</div>
	);
};
