import { Phone, PlusCircle } from "@phosphor-icons/react";
import { event } from "../shared/events";
import { useAuth0 } from "@auth0/auth0-react";

export const FooterCta = (props) => {
	const { setShowModal, practicePhone } = props;
	const { getAccessTokenSilently } = useAuth0();

	const handleCtaClick = async (ctaElement: string) => {
		event({
			eventName: "Click",
			meta: {
				element: ctaElement,
			},
			getAccessTokenSilently,
		});
	};

	return (
		<div className="sticky bottom-0 px-2 py-4 w-full justify-center flex space-x-2 lg:space-x-10 font-semibold text-sm">
			<button
				type="button"
				onClick={() => {
					handleCtaClick("ReferPatient");
					setShowModal(true);
				}}
				className="flex w-1/2 lg:w-1/5 items-center rounded-3xl shadow-lg justify-center text-center p-4 bg-topo-blue text-white dark:bg-gray-800 space-x-2 hover:cursor-pointer hover:bg-blue-800"
			>
				<div className="bg-transparent rounded-2xl">
					<PlusCircle color="white" />
				</div>
				<div>Refer Patient</div>
			</button>
			<a
				href={`tel:${practicePhone}`}
				className="flex w-1/2 lg:w-1/5 items-center rounded-3xl shadow-lg justify-center text-center p-4 bg-topo-blue text-white dark:bg-gray-800 space-x-2  hover:cursor-pointer hover:bg-blue-800"
				onClick={() => {
					handleCtaClick("CallTopo");
				}}
			>
				<div className="rounded-2xl">
					{/* <Phone color="#0542FC" /> */}
					<Phone color="white" />
				</div>
				<div>Call Topo</div>
			</a>
		</div>
	);
};
