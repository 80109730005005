import { MetabaseEmbed } from "../components/metabase-embed";
import { useShowQuery } from "../shared/use-rest-query";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "../shared/events";
import { useEffect } from "react";
import { useIsTabFocused } from "../shared/use-is-tab-focused";

export const Metrics = () => {
	const {
		data: { data: metabaseURL },
	} = useShowQuery("public/metabase_iframes", "provider_dashboard_metrics", {
		params: "bordered=false",
	});
	const isTabFocused = useIsTabFocused();
	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		event({
			eventName: `MetricsView${isTabFocused ? "Start" : "End"}`,
			getAccessTokenSilently,
		});
	}, [isTabFocused, getAccessTokenSilently]);

	useEffect(() => {
		return () => {
			event({
				eventName: "MetricsViewEnd",
				getAccessTokenSilently,
			});
		};
	}, [getAccessTokenSilently]);

	return (
		<div className="h-full p-2">
			<div className="bg-white shadow-md rounded-lg p-1 max-w-xl md:max-w-full">
				{/* <div className="p-4 text-3xl font-bold">Metrics</div> */}
				<MetabaseEmbed title="Metrics" url={metabaseURL} />
			</div>
		</div>
	);
};
