import { useContext } from "react";
import { ModalContext } from "../modal";
import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";

export const ReferPatientModalSuccess = (props) => {
	const { setShowModal } = useContext(ModalContext);
	const { practiceStudies } = props;
	const altimmune = new URL(
		"../../assets/altimmune.png",
		import.meta.url,
	).toString();
	const closeModal = () => setShowModal(false);
	return (
		<>
			{/* Modal header */}
			<div className="flex items-center justify-between p-6 md:p-5 border-b rounded-t dark:border-gray-600">
				<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
					Success!
				</h3>
				<button
					onClick={closeModal}
					type="button"
					className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
					data-modal-hide="default-modal"
				>
					<svg
						className="w-3 h-3"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 14 14"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
						/>
					</svg>
					<span className="sr-only">Close modal</span>
				</button>
			</div>
			{/* Modal body */}
			<div className="p-4 pl-6 md:p-5 space-y-4">
					<p className="text-sm text-gray-500 dark:text-gray-400">
					Topo has received your referral. Here's what comes next.
					</p>
				<div className="space-y-2">
				<Alert color="success" theme={{borderAccent: 'border'}} withBorderAccent={true}>
								<span className="font-medium">1.</span> Patient Identified
							</Alert>
				<Alert color="blue" theme={{borderAccent: 'border'}} withBorderAccent={true}>
								<span className="font-medium">2.</span> Topography Follow Up
							</Alert>
				<Alert color="default" theme={{borderAccent: 'border'}} withBorderAccent={true}>
								<span className="font-medium">3.</span> Topography Screening Visit
							</Alert>
				<Alert color="default" theme={{borderAccent: 'border'}} withBorderAccent={true}>
								<span className="font-medium">4.</span> Topography Enrollment
							</Alert>
				</div>
			</div>
			{/* Modal footer */}
			<div className="flex justify-between items-center py-4 px-3 md:px-2 border-t border-gray-200 rounded-b dark:border-gray-600">
				{/* <button
					onClick={() => closeModal()}
					data-modal-hide="default-modal"
					type="button"
					className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
				>
					Close
				</button> */}
				<button
					data-modal-hide="default-modal"
					type="button"
					onClick={closeModal}
					className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-topo-blue rounded-lg hover:bg-blue-800 hover:text-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
				>
					Close
				</button>
			</div>
		</>
	);
};
